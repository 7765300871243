// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-credits-js": () => import("./../../../src/pages/all/credits.js" /* webpackChunkName: "component---src-pages-all-credits-js" */),
  "component---src-pages-all-js": () => import("./../../../src/pages/all.js" /* webpackChunkName: "component---src-pages-all-js" */),
  "component---src-pages-all-projects-js": () => import("./../../../src/pages/all/projects.js" /* webpackChunkName: "component---src-pages-all-projects-js" */),
  "component---src-pages-all-students-js": () => import("./../../../src/pages/all/students.js" /* webpackChunkName: "component---src-pages-all-students-js" */),
  "component---src-pages-cinema-bigbuild-js": () => import("./../../../src/pages/cinema/bigbuild.js" /* webpackChunkName: "component---src-pages-cinema-bigbuild-js" */),
  "component---src-pages-cinema-fashion-js": () => import("./../../../src/pages/cinema/fashion.js" /* webpackChunkName: "component---src-pages-cinema-fashion-js" */),
  "component---src-pages-cinema-fineart-js": () => import("./../../../src/pages/cinema/fineart.js" /* webpackChunkName: "component---src-pages-cinema-fineart-js" */),
  "component---src-pages-cinema-js": () => import("./../../../src/pages/cinema.js" /* webpackChunkName: "component---src-pages-cinema-js" */),
  "component---src-pages-cinema-viscom-js": () => import("./../../../src/pages/cinema/viscom.js" /* webpackChunkName: "component---src-pages-cinema-viscom-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-exhibition-js": () => import("./../../../src/pages/exhibition.js" /* webpackChunkName: "component---src-pages-exhibition-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-radio-episode-1-js": () => import("./../../../src/pages/radio/episode1.js" /* webpackChunkName: "component---src-pages-radio-episode-1-js" */),
  "component---src-pages-radio-episode-2-js": () => import("./../../../src/pages/radio/episode2.js" /* webpackChunkName: "component---src-pages-radio-episode-2-js" */),
  "component---src-pages-radio-episode-3-js": () => import("./../../../src/pages/radio/episode3.js" /* webpackChunkName: "component---src-pages-radio-episode-3-js" */),
  "component---src-pages-radio-js": () => import("./../../../src/pages/radio.js" /* webpackChunkName: "component---src-pages-radio-js" */),
  "component---src-pages-specialism-js": () => import("./../../../src/pages/specialism.js" /* webpackChunkName: "component---src-pages-specialism-js" */),
  "component---src-templates-collection-page-js": () => import("./../../../src/templates/collection-page.js" /* webpackChunkName: "component---src-templates-collection-page-js" */),
  "component---src-templates-specialism-page-js": () => import("./../../../src/templates/specialism-page.js" /* webpackChunkName: "component---src-templates-specialism-page-js" */),
  "component---src-templates-student-page-js": () => import("./../../../src/templates/student-page.js" /* webpackChunkName: "component---src-templates-student-page-js" */)
}

